/* #region  -imports */

import {
	Scene,
	Color,
	Mesh,
	MeshNormalMaterial,
	MeshBasicMaterial,
	CubeRefractionMapping,
	PerspectiveCamera,
	WebGLRenderer,
	WebGLRenderTarget,
	Vector3,
	DoubleSide,
	FrontSide,
	LatheGeometry,
	CubicBezierCurve3,
	DirectionalLight,
	AmbientLight,
	BackSide,
	CubeTextureLoader,
	RGBAFormat,
	LinearFilter,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { HalftonePass } from "three/examples/jsm/postprocessing/HalftonePass.js";
import { ClearPass } from "three/examples/jsm/postprocessing/ClearPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
import {
	MaskPass,
	ClearMaskPass,
} from "three/examples/jsm/postprocessing/MaskPass.js";
import { throttle } from "underscore";
/* #endregion */

/* #region  -create UI */
var m1 = document.getElementById("input_w1");
var m2 = document.getElementById("input_w2");
var m3 = document.getElementById("input_height");

/* #endregion */

/* #region  -create threejs rendering */

/* #region  --create scene */

const camera = new PerspectiveCamera(
	50,
	window.innerWidth / window.innerHeight,
	1,
	10000
);
camera.position.y = 300;
camera.position.z = 400;
const scene = new Scene();

const renderer = new WebGLRenderer({
	powerPreference: "high-performance",
	antialias: true,
	alpha: true,
});

renderer.setPixelRatio(window.devicePixelRatio);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.render(scene, camera);
renderer.setClearColor(0x000000, 0);
renderer.autoClear = false;
document.body.appendChild(renderer.domElement);
renderer.domElement.id = "render_element";
let width = renderer.domElement.offsetWidth;
let height = renderer.domElement.offsetHeight;
let aspect = width / height;

camera.aspect = aspect;
camera.updateProjectionMatrix();
renderer.setSize(width, height);

var renderTarget = new WebGLRenderTarget(window.innerWidth, window.innerWidth, {
	minFilter: LinearFilter,
	magFilter: LinearFilter,
	format: RGBAFormat,
	stencilBuffer: true,
});

const composer = new EffectComposer(renderer, renderTarget);

const renderPass = new RenderPass(
	scene,
	camera,
	null,
	new Color(0x000000, 0),
	0
);
const maskPass1 = new MaskPass(scene, camera);

let halftonePass = new HalftonePass(width, height, {
	shape: 2,
	radius: 3,
	rotateR: Math.PI / 12,
	rotateB: (Math.PI / 12) * 2,
	rotateG: (Math.PI / 12) * 3,
	scatter: 0,
	blending: 1,
	blendingMode: 1,
	greyscale: false,
	disable: false,
});
const clearMaskPass = new ClearMaskPass();
const outputPass = new ShaderPass(CopyShader);
outputPass.renderToScreen = true;

composer.addPass(renderPass);
composer.addPass(maskPass1);
composer.addPass(halftonePass);
composer.addPass(clearMaskPass);
composer.addPass(outputPass);

function render() {
	composer.render();
	// renderer.render(scene, camera);
}

let render_throttled = throttle(render, 30);

const controls = new OrbitControls(camera, renderer.domElement);
controls.enablePan = false;
controls.enableKeys = false;
controls.enableZoom = false;
controls.enableDamping = true;

controls.target.y = 110;
controls.update();
/* #endregion */

/* #region  --create geometries */

function create_geometry(m1, m2, m3) {
	//m1 is radius at top, at 200 units
	//m3 is height at which width is half way top
	//

	// create profile curve
	let curve_outside = new CubicBezierCurve3(
		new Vector3(m1, 200, 0), //make point at x=m1, y=200 (ie up), on xy axis
		new Vector3(m1 / 2, m3, 0), //
		new Vector3(m2, 0, 0),
		new Vector3(0, 0, 0)
	);
	let points_outside = curve_outside.getPoints(20);

	let curve_inside = new CubicBezierCurve3(
		new Vector3(m1, 200, 0),
		new Vector3(m1 / 2.1, m3, 0),
		new Vector3(m2 * 0.9, 0, 0),
		new Vector3(0, 0, 0)
	);
	let points_inside = curve_inside.getPoints(20);
	points_inside.reverse();

	let points = points_outside.concat(points_inside);

	// lathe geometry
	let geometry = new LatheGeometry(points, 30);
	return geometry;
}

let geometry = create_geometry(m1.value, m2.value, m3.value);
let env_cube_nx = require("./images/blinds_512/nx.webp");
let env_cube_ny = require("./images/blinds_512/ny.webp");
let env_cube_nz = require("./images/blinds_512/nz.webp");
let env_cube_px = require("./images/blinds_512/px.webp");
let env_cube_py = require("./images/blinds_512/py.webp");
let env_cube_pz = require("./images/blinds_512/pz.webp");
let cubemap_texture = new CubeTextureLoader().load(
	[
		env_cube_px,
		env_cube_nx,
		env_cube_py,
		env_cube_ny,
		env_cube_pz,
		env_cube_nz,
	],
	() => {
		render();
		onWindowResize();
	}
);
const glassMaterial = new MeshBasicMaterial({
	color: 0xffffff,
	envMap: cubemap_texture,
	refractionRatio: 0.8,
	side: BackSide,
});
glassMaterial.envMap.mapping = CubeRefractionMapping;
const glass = new Mesh(geometry, glassMaterial);
glass.geometry.dynamic = true;

function create_saucer() {
	let width = 120;
	let height = 30;
	// create profile curve
	let curve_outside = new CubicBezierCurve3(
		new Vector3(width, height, 0),
		new Vector3(width * 0.95, height * -0.8, 0),
		new Vector3(0, 0, 0)
	);
	let points_outside = curve_outside.getPoints(20);

	let curve_inside = new CubicBezierCurve3(
		new Vector3(width, height, 0),
		new Vector3(width * 0.9, height * -0.8, 0),
		new Vector3(0, 0, 0)
	);
	let points_inside = curve_inside.getPoints(20);
	points_inside.reverse();

	let points = points_outside.concat(points_inside);

	// lathe geometry
	let geometry_saucer = new LatheGeometry(points, 30);
	const mesh_saucer = new Mesh(geometry_saucer, glassMaterial);
	mesh_saucer.geometry.dynamic = true;
	return mesh_saucer;
}
let saucer = create_saucer();

scene.add(glass);
scene.add(saucer);
/* #endregion */

/* #endregion */

/* #region  -create Interactivity */
const animate = () => {
	controls.update();
};
renderer.setAnimationLoop(animate);

let input_change = () => {
	glass.geometry = create_geometry(m1.value, m2.value, m3.value);
	glass.geometry.verticesNeedUpdate = true;
	render();
};
m1.oninput = m2.oninput = m3.oninput = input_change;

controls.addEventListener("change", render_throttled);

const onWindowResize = () => {
	width = window.innerWidth;
	height = window.innerHeight;
	aspect = width / height;
	camera.aspect = aspect;
	camera.updateProjectionMatrix();
	renderer.setSize(width, height);
	composer.setSize(width, height);

	halftonePass = new HalftonePass(width, height);

	render();
};
window.addEventListener("resize", onWindowResize);
/* #endregion */
